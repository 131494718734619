import { render, staticRenderFns } from "./examCorrection.vue?vue&type=template&id=82f540ea&scoped=true&"
import script from "./examCorrection.vue?vue&type=script&lang=js&"
export * from "./examCorrection.vue?vue&type=script&lang=js&"
import style0 from "./examCorrection.vue?vue&type=style&index=0&id=82f540ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82f540ea",
  null
  
)

export default component.exports